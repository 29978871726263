<template>
  <div class="gifts">
    <main-nav></main-nav>
    <v-content>
      <v-container>

        <h1 class="headline">Your Gift Credits</h1>

        <div class="pb-4 text-xs-center display-1 pink--text text--darken-2">
          {{credits}} Gift Credits
        </div>

        <h1 class="headline">Your Sent Gifts</h1>

        <v-progress-circular v-if="typeof sent === 'undefined' && _admin"
          indeterminate
          :size="28"
          color="blue"
        ></v-progress-circular>

        <div v-for="(gift, i) in sent"
          :key="'sent__' + i"
        >
          <gift-item :item="gift" :id="i"></gift-item>
        </div>

        <div class="pb-4">

        </div>

        <!-- ***********************************************  -->

        <h1 class="headline">Your Received Gifts</h1>

        <v-progress-circular v-if="typeof received === 'undefined' && _admin"
          indeterminate
          :size="28"
          color="blue"
        ></v-progress-circular>

        <div v-for="(gift, i) in received"
          :key="'received__' + i"
        >
          <gift-item :item="gift" :id="i"></gift-item>
        </div>

        <!-- ***********************************************  -->



      </v-container>
    </v-content>
  </div>
</template>

<script>
import MainNav from '@/components/navs/MainNav.vue'

import GiftItem from '@/components/gifts/GiftItem.vue'

export default {
  name:'gifts',
  components: {
    MainNav,
    GiftItem
  },
  data() {
    return {}
  },
  computed: {
    sent() { return this.$store.getters.giftsSent; },
    received() { return this.$store.getters.giftsReceived; },

    _admin() { return this.$store.getters.admin; },

    credits() {
      let res = 0;
      if(this.$store.getters.admin) { res = this.$store.getters.admin.credits; }
      return res;
    },

  },
  watch: {
    // _admin() { this.ping(); }
  },
  mounted() {
    // this.ping();
  },
  methods: {

    // not needed anymore, getting gifts upon loggin in
    ping() {
      if(this.$store.getters.admin) {
        this.$store.dispatch('pingGifts');
      }
    },

    editGift(key, gift) {
      this.$store.dispatch('setGiftAsCustomizerStageIntent', { key, gift })
      .then(() => {
        this.$router.push('/customizer');
      });
    },

  }
}
</script>
